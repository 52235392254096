import axios from 'axios';
import { Toast } from 'vant';
import store from '@/store';
import i18n from '@/i18n';


axios.defaults.baseURL = '/api';

export function showErrorMessage(message, code) {
  const i18nKey = `error_response_${code}`;
  if (i18n.te(i18nKey)) {
    Toast(i18n.t(i18nKey));
  } else {
    Toast(message);
  }
}

axios.interceptors.request.use(
  (config) => {
    store.addLoadingRef();
    return config;
  },
  (error) => {
    store.minusLoadingRef();
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    store.minusLoadingRef();
    const { data } = response;
    const { responseType } = response.config;
    if (responseType === 'blob') {
      let fileReader = new FileReader();
      fileReader.onload = function() {
          return data;
      };
      fileReader.readAsText(data);
      return data;
    } else {
      if (data.code !== 0) {
        showErrorMessage(data.msg, data.code);
        return Promise.reject(data);
      }
    }
    return data.data;
  },
  (error) => {
    store.minusLoadingRef();
    if (process.env.NODE_ENV !== 'production') {
      console.log(error);
    }
    if (error.response) {
      const message = 'unknown error';
      showErrorMessage(`${message} ${error.response.status}`);
    } else if (error.request) {
      if (error.message === 'Network Error') {
        showErrorMessage(i18n.t('common_network_error'));
        return false;
      }
      showErrorMessage(error.message);
    } else {
      showErrorMessage(error.message);
    }
    return Promise.reject(error);
  },
);

export default axios;
