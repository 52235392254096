import {repayService} from '@/services';
export default {
  loadingRef: 0,
  configs: {},
  addLoadingRef() {
    this.loadingRef += 1;
  },
  minusLoadingRef() {
    if (this.loadingRef > 0) {
      this.loadingRef -= 1;
    }
  },
  setConfig(key, value){
    this.configs[key] = value;
  },
  async getConfig(configName) {
    if (!this.configs[configName.toLowerCase()]) {
      await this.initConfig()
    }
    console.log("this.configs:",this.configs)
    return this.configs[configName.toLowerCase()];
  },
  async initConfig() {
    await repayService.getConfigs();
  },
};
