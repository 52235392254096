import axios from '@/common/axios';
import store from '@/store';

export default {
    //获取信息
    getRepayInfo(params) {
        return axios.post('/repay/info', params, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        });
    },
    //提交用户付款钱包号码
    paymentConfirm(params) {
        return axios.post('/repay/wallet/confirm', params, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        });
    },
    //修改用户付款钱包号码
    updateWallet(params) {
        return axios.post('/repay/wallet/update', params, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        });
    },
    queryResult(params) {
        return axios.post('/v2/repay/result', params, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        });
    },
    uploadSms(params) {
        return axios.post('/repay/sms/upload', params, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        });
    },
    async getConfigs() {
        const data = await  axios.post('/configs');
        for (let item of data) {
            let key = item.key;
            let value = item.value;
            try {
                value = JSON.parse(value);
            } catch (e) {
                //忽略
            }
            store.setConfig(key.toLowerCase(), value);
        }
    },
}