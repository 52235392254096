import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocate from 'element-ui/lib/locale/lang/en';
import zhLocate from 'element-ui/lib/locale/lang/zh-CN';
import locale from 'element-ui/lib/locale';

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: process.env.DEPLOY_CONFIGS.lang || 'en',
  messages: {
    en: {...require('./lang/en.json'), ...enLocate},
    zh: {...require('./lang/zh.json'), ...zhLocate},
  },
});

locale.i18n((key, value) => i18n.t(key, value));

export default i18n;
